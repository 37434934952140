$(function () {
    var w = $(window).width();

    $('.hamburger').click(function (e) {
        e.preventDefault();

        $('.mobil__overlay').fadeIn(300);
        $('.mobile__nav').fadeIn(500);
    });

    $('.mobil__overlay').click(function (e) {
        e.preventDefault();

        $('.mobil__overlay').fadeOut(500);
        $('.mobile__nav').fadeOut(300);
    });


    // Якорні посилання
    $('.scrollMove[href^="#"]').bind('click.smoothscroll',function (e) {
        e.preventDefault();

        var target = this.hash,
            $target = $(target);

        $('html, body').stop().animate({'scrollTop': $target.offset().top  }, 500, 'swing', function () {
            window.location.hash = target;
        });
    });



    //Сортування
    $('.input__select__text').click(function () {
        var
            thisContainer = $(this).closest('.input__selsct');

        if(!thisContainer.hasClass('openedSelect')){
            setTimeout(function(){
                thisContainer.addClass('openedSelect');
            }, 50);
        } else {
            thisContainer.removeClass('openedSelect');
        }

    });

    $('.input__option').click(function () {
        var
            $this = $(this),
            thisContainer = $this.closest('.input__selsct'),
            thisText = thisContainer.find('.input__select__text'),
            thisInput = thisContainer.find('.filterInput'),
            thisVal = $this.text();

        $this.addClass('active');
        $this.siblings().removeClass('active');
        thisText.text(thisVal);
        thisText.addClass('blackText');
        thisInput.val(thisVal);
        thisContainer.removeClass('openedSelect');
    });

    $('body').click(function (e) {
        if($('.input__selsct').hasClass('openedSelect')) {
            $('.input__selsct').removeClass('openedSelect');
        }
    });


    $('.input__sort').click(function (e) {
       e.preventDefault();
    });



    //Таби в мовному турі
    $('.tour__tabs a').click(function (e) {
        e.preventDefault();

        var
            thisHash = $(this).attr('href'),
            thisLi = $(this).closest('li'),
            thisTab = $(''+thisHash+'');

        $(this).addClass('active');
        thisLi.siblings().find('a').removeClass('active');
        thisTab.show();
        thisTab.siblings().hide();
        $('html, body').stop().animate({'scrollTop': thisTab.offset().top - 40  }, 1000, 'swing', function () {});
    });
});

